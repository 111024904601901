<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 관련 MOC 목록 -->
      <c-table
        ref="gridmoc"
        title="LBL0003330"
        tableId="gridmoc"
        :columns="gridmoc.columns"
        :data="gridmoc.data"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        @linkClick="openPopup"
      >
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-moc',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        param: {},
        closeCallback: null,
      },
      editable: true,
      gridmoc: {
        columns: [
          {
            name: 'initiativeDt',
            field: 'initiativeDt',
            // 발의일
            label: 'LBL0001905',
            align: 'center',
            style: 'width:80px',
            sortable: true
          },
          {
            name: 'mocTitle',
            field: 'mocTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            sortable: true,
            style: 'width:280px',
            type: 'link',
          },
          {
            name: 'mocStepName',
            field: 'mocStepName',
            // 진행단계
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:90px',
            sortable: true
          },
          {
            name: 'mocTypeName',
            field: 'mocTypeName',
            // 변경구분
            label: 'LBL0001906',
            align: 'center',
            style: 'width:70px',
            sortable: true
          },
        ],
        data: [],
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.moc.relationEquip.list.url;
      this.getList();
    },
    getList() {
      if (this.param.equipmentCd) {
        this.$http.url = this.$format(this.listUrl, this.param.equipmentCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridmoc.data = _result.data;
        },);
      }
    },
    openPopup(row) {
      this.popupOptions.title = 'LBL0001921'; // 변경관리 상세
      this.popupOptions.param = {
        sopMocId: row ? row.sopMocId : '',
        mocTypeCd: row ? row.mocTypeCd : 'MT00000001',
        mocStepCd: row ? row.mocStepCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>